<template>
    <div>
        <div>
            <ul class="list-unstyle pagination">
                <li class="page prev" :class="current_page == 1?'disabled':''" @click="paginatePrev(current_page, 'prev', requestStatus)">
                <i class="fa fa-angle-left"></i> Prev
                </li>
                <ul class="list-unstyled">
                <li class="page" v-for="(page, index) in links" :key="index+1" :class="page.active ? 'active':''" @click="getPagination(page.label, requestStatus)">
                {{ page.label }}
                </li>
                <!-- <li class="dots" v-if="links.length > 4">...</li> -->
                </ul>
                <li class="page next" :class="current_page == links.length?'disabled':''"  @click="paginateNext(current_page, 'next', requestStatus)">
                Next <i class="fa fa-angle-right"></i>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        current_page:{
            type: Number
        },
        requestStatus:{
            type: String
        },
        links:{
            type: Array
        },
    },
    data(){
        return{

        }
    },
    methods:{
        paginatePrev(c_page, type, filter){
            const values = {c_page: c_page, filter: filter}
            this.$emit('paginatePrev', values)

        },
        getPagination(page, filter){
            const values = {page: page, filter: filter}
            this.$emit('getPagination', values)
        },
        paginateNext(c_page, type, filter){
            const values = {c_page: c_page, filter: filter}
            this.$emit('paginateNext', values)

        },
    }
}
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>