export const getTransportsData = (page) => ({
  method: "get",
  url: `pricing/transportation?page=${page}`,
})

export const createTransport = (data) => ({
  method: "post",
  url: `pricing/transportation/create`,
  config: {
    data
  }
})

export const getTransportByIdRequest = (id) => ({
  method: "get",
  url: `pricing/transportation/${id}/view`
})
export const deleteTransport = (id) => ({
  method: "delete",
  url: `pricing/transportation/${id}/delete`,
})

export const updateTransport = (id, data) => ({
  method: "post",
  url: `pricing/transportation/${id}/update`,
  config: {
    data
  }
})