<template>
    <b-modal id="edit-transport" hide-footer size="lg" centered hide-header>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <div class="w-100 info-container">
          <div class="header">
            <h3>{{ $t("GLOBAL.ADD_TRANSPORT") }}</h3>
            <!-- <p>{{ $t("ACCOUNTS.ADD_USER_SUBTITLE") }}</p> -->
          </div>
          <div class="form">
            <b-row>
              <b-col>
                <b-form-group class="mb-3">
                  <TextField
                    v-model="transport.name_ar"
                    :label="$t('GLOBAL.NAME_AR')"
                    :name="$t('GLOBAL.NAME_AR')"
                    rules="required"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-3">
                  <TextField
                    v-model="transport.name_en"
                    :label="$t('GLOBAL.NAME_EN')"
                    :name="$t('GLOBAL.NAME_EN')"
                    rules="required"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <TextField
                    v-model="transport.km_price"
                    :label="$t('GLOBAL.KM PRICE')"
                    :name="$t('GLOBAL.KM PRICE')"
                    rules="required|number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <TextField
                    v-model="transport.start_price"
                    :label="$t('GLOBAL.START PRICE')"
                    :name="$t('GLOBAL.START PRICE')"
                    rules="required|number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <TextField
                    v-model="transport.fixed_price"
                    :label="$t('GLOBAL.FIXED PRICE')"
                    :name="$t('GLOBAL.FIXED PRICE')"
                    rules="required|number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group class="mb-3">
                        <SelectSearch
                        v-model="transport.status"
                        :label="$t('ACCOUNTS.STATUS')"
                        :name="$t('ACCOUNTS.STATUS')"
                        :options="statusList"
                        :get-option-label="(option) => option.name"
                        :reduce="(option) => option.id"
                        rules="required"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <Button @click="handleSaveChanges">{{ $t("GLOBAL.SAVE_CHANGES") }}</Button>
                <Button custom-class="mx-3 cancel-btn" @click="handleCancel">{{
                  $t("GLOBAL.CANCEL")
                }}</Button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
  </template>
  <script>
  import { updateTransport } from "@/api/transports.js"
  import { mapActions } from "vuex"
  export default {
    props: {
      transport: {
        type: Object,
      },
    },
    data() {
      return {
        formValues: {
            name_ar: "",
            name_en: "",
            km_price: "",
            start_price: "",
            fixed_price: "",
            status: 1
        },
        statusList: [
          {
            id: 1,
            name: "نشط"
          },
          {
            id: 2,
            name: "غير نشط"
          }
        ],
        roles: [
        {
            id: 'admin',
            name: "مدير"
          },
          {
            id: 'driver',
            name: "سائق"
          }
        ],
        permissionsList: [
          {
            id: 1,
            name: "مراجعة"
          },
          {
            id: 2,
            name: "متابعة"
          }
        ]
      }
    },
    mounted() {
      console.log(this.transport)
    },
    methods: {
      ...mapActions(["ShowToast"]),
      handleCancel() {
        this.$bvModal.hide("add-transport")
      },
      handleSaveChanges() {
        this.loading = true
        this.ApiService(updateTransport(this.transport.id, this.transport))
        .then(res => {
          console.log(res)
          if (res.data.status == "success") {
            this.ShowToast({
              title: this.$t("Transport Updated"),
              type: "success",
              message: this.$t("Transport Updated")
            })
            return res
          }
        })
        .finally(() => {
          setInterval(()=>{
            window.location.reload()
          }, 500)
        })
        // this.$emit("editTransport", this.formValues)
      },
      handleData(){
        this.formValues = this.transport
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  @import "./index.scss";
  </style>
  